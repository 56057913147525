import React from 'react';
import './HeaderWithDua.css'; // Ensure to include the CSS file for styling

const HeaderWithDua = () => {
  return (
    <div className="header-dua">
      <h1 className="title">بِسْمِ ٱللَّٰهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ</h1>
      <p className="dua-arabic">
        اللهم أعني على ذكرك وشكرك ، وحسن عبادتك
      </p>
      <p className="dua-translation">
        O Allah, help me to remember You, to give You thanks, and to perform Your worship in the best manner.
      </p>
    </div>
  );
};

export default HeaderWithDua;
