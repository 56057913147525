import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage'; // Your home page component
import LoginPage from './login/LoginPage'; // The new login page component
import CalendarComponent from './Calendar/calendar'; // Example of another component
import { AuthProvider } from './AuthContext';
import './App.css'; // Import your main CSS file

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/calendar" element={<CalendarComponent />} />
          {/* Add other routes here */}
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
