import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import './Statistics.css'; // Add this import for custom styling

const sampleStats = [
  {
    "brother": "Abdul Rahman",
    "total": 2,
    "mode": 0,
    "mean": 1.0,
    "stdDev": 0.5,
    "min": 0,
    "q1": 0,
    "median": 1,
    "q3": 2,
    "max": 2,
    "iqr": 2
  },
  {
    "brother": "Ahmed",
    "total": 36,
    "mode": 7,
    "mean": 6.0,
    "stdDev": 2.0,
    "min": 3,
    "q1": 5,
    "median": 6,
    "q3": 7,
    "max": 9,
    "iqr": 2
  },
  {
    "brother": "Hasan",
    "total": 38,
    "mode": 7,
    "mean": 6.5,
    "stdDev": 1.5,
    "min": 4,
    "q1": 6,
    "median": 7,
    "q3": 7,
    "max": 8,
    "iqr": 1
  },
  {
    "brother": "Rayan",
    "total": 0,
    "mode": 0,
    "mean": 0,
    "stdDev": 0,
    "min": 0,
    "q1": 0,
    "median": 0,
    "q3": 0,
    "max": 0,
    "iqr": 0
  },
  {
    "brother": "Seif",
    "total": 24,
    "mode": 7,
    "mean": 6.0,
    "stdDev": 1.8,
    "min": 2,
    "q1": 5,
    "median": 6,
    "q3": 7,
    "max": 9,
    "iqr": 2
  },
  {
    "brother": "Serag",
    "total": 0,
    "mode": 0,
    "mean": 0,
    "stdDev": 0,
    "min": 0,
    "q1": 0,
    "median": 0,
    "q3": 0,
    "max": 0,
    "iqr": 0
  }
];

const Statistics = () => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    // In real implementation, you would fetch data from Firebase
    setStats(sampleStats);
  }, []);

  return (
    <div className="statistics-container">
      <CSVLink data={stats} filename={"statistics.csv"} className="btn btn-primary" target="_blank">
        Export to CSV
      </CSVLink>
      <table className="statistics-table">
        <thead>
          <tr>
            <th>Brother</th>
            <th>Total</th>
            <th>Mode</th>
            <th>Mean</th>
            <th>Std. Dev</th>
            <th>Min</th>
            <th>Q1</th>
            <th>Median</th>
            <th>Q3</th>
            <th>Max</th>
            <th>IQR</th>
          </tr>
        </thead>
        <tbody>
          {stats.map((stat, index) => (
            <tr key={index}>
              <td>{stat.brother}</td>
              <td>{stat.total}</td>
              <td>{stat.mode}</td>
              <td>{stat.mean}</td>
              <td>{stat.stdDev}</td>
              <td>{stat.min}</td>
              <td>{stat.q1}</td>
              <td>{stat.median}</td>
              <td>{stat.q3}</td>
              <td>{stat.max}</td>
              <td>{stat.iqr}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Statistics;
