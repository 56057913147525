import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCd7AbHN9hb7r2w8mHwMbUR0VE1Q3QUHaA",
    authDomain: "fam-fajr-at-masjid.firebaseapp.com",
    projectId: "fam-fajr-at-masjid",
    storageBucket: "fam-fajr-at-masjid.appspot.com",
    messagingSenderId: "421088312188",
    appId: "1:421088312188:web:37de8a5c628082ef2002f9",
    measurementId: "G-X0LS1NT6BL"
  };

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const firestore = getFirestore(app);