import React, { useState, useEffect } from "react";
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import { Navigation } from "./components/navigation";
import HeaderWithDua from "./components/HeaderWithDua";
import WeeklyCalendarGrid from "./Calendar/WeeklyCalendarGrid";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Contact } from "./components/contact";
import CalendarComponent from "./Calendar/calendar";
import Statistics from './components/Statistics';
import JsonData from "./data/data.json";
import './Home.css'; // Ensure to include the new CSS file

const HomePage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div className="home-container">
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <HeaderWithDua />
      {currentUser ? (
        <>
          <p>Hello, {currentUser.email}</p>
          <div className="button-container">
            <button className="button" onClick={() => navigate('/calendar')}>Go to Calendar</button>
            <button className="button" onClick={handleLogout}>Logout</button>
          </div>
          <WeeklyCalendarGrid />
          <CalendarComponent />
          <Statistics />
        </>
      ) : (
        <>
          <p>Please sign in to access your calendar and other features.</p>
          <button className="button" onClick={() => navigate('/login')}>Sign In / Sign Up</button>
        </>
      )}
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default HomePage;
