import React, { useEffect, useState } from 'react';
import './WeeklyCalendarGrid.css'; // Ensure to include the CSS file for styling

const getWeekRange = (startDate) => {
  const start = new Date(startDate);
  const end = new Date(startDate);
  end.setDate(start.getDate() + 6);
  return {
    range: `${start.toLocaleDateString('en-US')} - ${end.toLocaleDateString('en-US')}`,
    days: [start, new Date(start.setDate(start.getDate() + 1)), new Date(start.setDate(start.getDate() + 1)), new Date(start.setDate(start.getDate() + 1)), new Date(start.setDate(start.getDate() + 1)), new Date(start.setDate(start.getDate() + 1)), new Date(start.setDate(start.getDate() + 1))]
  };
};

const generateWeeks = (numWeeks) => {
  const weeks = [];
  let current = new Date();
  current.setDate(current.getDate() - (current.getDay() + 1)); // Set to the previous Wednesday
  for (let i = 0; i < numWeeks; i++) {
    weeks.push(getWeekRange(current));
    current.setDate(current.getDate() + 7);
  }
  return weeks;
};

const WeeklyCalendarGrid = () => {
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    setWeeks(generateWeeks(6)); // Generate 6 weeks
  }, []);

  return (
    <div className="weekly-calendar-grid">
      <table>
        <thead>
          <tr>
            <th>Date</th>
            {['Sa', 'Su', 'M', 'Tu', 'W', 'Th', 'F'].map((day, index) => (
              <th key={index}>{day}</th>
            ))}
            <th>Week</th>
            <th>Score</th>
            <th>Week/Week</th>
            <th>Side Quest</th>
          </tr>
        </thead>
        <tbody>
          {weeks.map((week, index) => (
            <tr key={index}>
              <td>{week.range}</td>
              {week.days.map((day, dayIndex) => (
                <td key={dayIndex}>
                  <input type="checkbox" />
                </td>
              ))}
              <td>{index + 1}</td>
              <td>0</td>
              <td>0</td>
              <td><input type="checkbox" /></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WeeklyCalendarGrid;
