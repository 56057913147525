import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css';
import { firestore } from '../firebase';
import { useAuth } from '../AuthContext';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import HeaderWithDua from '../components/HeaderWithDua';
import WeeklyCalendarGrid from './WeeklyCalendarGrid';

const CalendarComponent = () => {
  const [value, setValue] = useState(new Date());
  const [checkedDates, setCheckedDates] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      const fetchCheckedDates = async () => {
        const userDoc = doc(firestore, 'checkedDates', currentUser.uid);
        const docSnap = await getDoc(userDoc);
        if (docSnap.exists()) {
          setCheckedDates(docSnap.data().dates || []);
        } else {
          // Create the document with an empty dates array if it doesn't exist
          await setDoc(userDoc, { dates: [] });
          setCheckedDates([]);
        }
      };
      fetchCheckedDates();
    }
  }, [currentUser]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000); // Update the current date every minute
    return () => clearInterval(interval);
  }, []);

  const onChange = (date) => {
    setValue(date);
  };

  const handleDateClick = async (date) => {
    const dateStr = date.toDateString();
    let updatedCheckedDates;
    if (checkedDates.includes(dateStr)) {
      updatedCheckedDates = checkedDates.filter(d => d !== dateStr);
    } else {
      updatedCheckedDates = [...checkedDates, dateStr];
    }
    setCheckedDates(updatedCheckedDates);

    if (currentUser) {
      await setDoc(doc(firestore, 'checkedDates', currentUser.uid), {
        dates: updatedCheckedDates
      });
    }
  };

  const isDateChecked = (date) => {
    return checkedDates.includes(date.toDateString());
  };

  const isDateOverdue = (date) => {
    const today = new Date();
    return date < today && !checkedDates.includes(date.toDateString());
  };

  const handleTodayClick = () => {
    handleDateClick(new Date());
  };

  return (
    <div className="calendar-component">
      <HeaderWithDua />
      <WeeklyCalendarGrid />
      <div className="calendar-container">
        <div className="sidebar">
          <h2>Today's Date</h2>
          <p>{currentDate.toDateString()}</p>
          <button onClick={handleTodayClick} className={isDateChecked(currentDate) ? 'checked' : ''}>
            {isDateChecked(currentDate) ? '✔️ Checked' : 'Mark as Checked'}
          </button>
        </div>
        <Calendar
          onChange={onChange}
          value={value}
          tileContent={({ date, view }) => view === 'month' && (
            <div>
              <button
                onClick={() => handleDateClick(date)}
                className={isDateChecked(date) ? 'checked' : isDateOverdue(date) ? 'unchecked' : ''}
              >
                {isDateChecked(date) ? '✔️' : ''}
              </button>
            </div>
          )}
          tileClassName={({ date }) =>
            date.toDateString() === currentDate.toDateString()
              ? isDateChecked(date)
                ? 'react-calendar__tile--now checked'
                : 'react-calendar__tile--now unchecked'
              : ''
          }
        />
      </div>
    </div>
  );
};

export default CalendarComponent;
